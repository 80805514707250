import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorAlert } from 'components/Shared/Icons/Icons';

export interface Props {
  id: string;
  values: any;
}

const InputErrorMessage: React.FC<Props> = (props) => {
  let { id, values = {} } = props;

  return (
    <div className="field-error-message-wrapper">
      <div className="error-icon-wrapper">
        <ErrorAlert />
      </div>
      <span className="error-message">
        <FormattedMessage id={id} values={values} defaultMessage="Invalid Field" />
      </span>

      <style jsx>{`
        .field-error-message-wrapper {
          line-height: 14px;

          .error-icon-wrapper {
            position: absolute;
          }

          .error-message {
            display: block;
            padding-left: 12px;
            font-style: italic;
            font-family: AvenirNextforINTUIT-Medium;
          }
        }
      `}</style>
    </div>
  );
};

export default InputErrorMessage;
