/* eslint-disable */
import {
  BankMutation_createPayments_WalletBankAccount as BankCreationResponse,
  Payments_Definitions_Payments_BankAccountInputTypeEnumInput as InputType,
  Payments_Definitions_Payments_BankAccountTypeEnum as BankAccountTypeEnumResponse,
  Payments_Definitions_Payments_BankAccountTypeEnumInput as BankAccountTypeEnumInput,
  Payments_Definitions_Payments_BankAccountTypeInput as BankAccountTypeInput,
  CCMutation_createPayments_WalletCard as CardCreationResponse,
  Payments_Definitions_Payments_CardTypeInput as CardTypeInput,
} from 'components/Core/WalletForms/queries/transformed/lib/schema';

export type BankAccountType = BankAccountTypeEnumInput | BankAccountTypeEnumResponse;
export { BankAccountTypeEnumInput };

export enum ParentTypeEnum {
  CUSTOMERS = 'customers',
  USERS = 'users',
  VENDORS = 'vendors',
}

export interface IParentInfo {
  parentId: string;
  parentType: ParentTypeEnum;
}

export interface IBankIdentifier {
  parentId: string;
  bankId: string;
}

export interface ICreateBankMutationVariables extends IParentInfo {
  bankAccount: IBankAccountVariables;
}

export interface IBankAccountVariables extends BankAccountTypeInput {
  bankCode: string;
  accountType: BankAccountTypeEnumInput;
  accountNumber: string;
  name: string;
  phone?: string;
  default?: boolean;
  inputType?: InputType;
}

export interface IBankAccountCreationResponse extends BankCreationResponse {}

export interface IParentInfo {
  parentId: string;
  parentType: ParentTypeEnum;
}

export interface IBankIdentifier {
  parentId: string;
  bankId: string;
}

export interface IBankAccountCreateParam extends IParentInfo {
  bankAccount: IBankAccountCreateData;
}

export interface ICardCreateParam extends IParentInfo {
  card: ICardCreateData;
}
export interface IUSBankAccountCreateData extends BankAccountTypeInput {
  bankCode: string;
  accountType: BankAccountTypeEnumInput;
  accountNumber: string;
  name: string;
  savePaymentMethod?: boolean;
  accountNumberConfirm: string;
}

export interface ICABankAccountCreateData extends BankAccountTypeInput {
  accountType: BankAccountTypeEnumInput;
  accountNumber: string;
  name: string;
  savePaymentMethod?: boolean;
  transitNumber: string;
  institutionNumber: string;
  zipCode: string;
}

export type IBankAccountCreateData = ICABankAccountCreateData | IUSBankAccountCreateData
export interface ICardCreateData extends CardTypeInput {
  number: string;
  cvc: string;
  name: string;
  savePaymentMethod?: boolean;
}

export type IBankAccountUpdateData = Pick<
  BankAccountTypeInput,
  Exclude<keyof BankAccountTypeInput, 'bankCode' | 'accountNumber'>
>;
export interface IBankAccountUpdateParam extends IParentInfo {
  id: string;
  bankAccount: IBankAccountUpdateData;
}

export type ICardUpdateData = Pick<
  CardTypeInput,
  Exclude<keyof CardTypeInput, 'number' | 'cvc' | 'cardType'>
>;
export interface ICardUpdateParam extends IParentInfo {
  id: string;
  card: ICardUpdateData & { number: string };
}

export interface IBankDeleteParam extends IParentInfo {
  id: string;
}
export interface ICardDeleteParam extends IParentInfo {
  id: string;
}

export interface IBankAccountCreationResponse extends BankCreationResponse {}

export interface ICardCreationResponse extends CardCreationResponse {}
