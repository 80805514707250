import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import debugCreator from 'debug';
import { cvcValidator } from 'components/Core/WalletForms/validations/src/cvc';
import { ValidationErrorEnum } from 'components/Core/WalletForms/validations/src/ValidationErrorEnum';

import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { REMOTE_BUTTON_ACCESS_FUNCTIONALITY } from 'store/wallet/slice';

import { colors, fontSize } from 'styles/cp';
import Input from 'components/Shared/Inputs/Input';
import { ErrorAlert } from 'components/Shared/Icons/Icons';

const debug = debugCreator('CvvInputControl');

export default class CvvInputControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Please check PaymentItem to see why we have multiple instances
      isTheActiveInstance: false,
      errors: [],
      touched: false,
    };

    this.errorTranslators = formErrorTranslator(props.pageMessages);
    this.ref = React.createRef();
    this.isAmex = props.cardType === 'amex';

    this.createPayment = this.createPayment.bind(this);
    this.onCvvInputFocus = this.onCvvInputFocus.bind(this);
    this.onCvvInputBlur = this.onCvvInputBlur.bind(this);
    this.onCvvInputChange = this.onCvvInputChange.bind(this);
  }

  componentDidMount() {
    debug('componentDidMount');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.ref.current.clientHeight > 0 && !this.state.isTheActiveInstance) {
      this.setState({
        isTheActiveInstance: true,
      });
    }

    if (this.state.isTheActiveInstance && !prevState.isTheActiveInstance) {
      this.props.bindFormSubmission({
        fn: this.createPayment,
        functionality: REMOTE_BUTTON_ACCESS_FUNCTIONALITY.CARD_CREATE_PAYMENT,
      });
    }
  }

  createPayment() {
    const { isTheActiveInstance, errors, touched } = this.state;
    if (isTheActiveInstance && touched && Array.isArray(errors) && errors.length === 0) {
      this.props.createPayment();
    } else {
      const { cvvInputValue } = this.props;
      const [, errors] = cvcValidator(cvvInputValue, { isAmex: this.isAmex });
      this.setState({
        touched: true,
        errors,
      });
    }
  }

  onCvvInputFocus() {
    if (!this.state.isTheActiveInstance) {
      this.setState({
        isTheActiveInstance: true,
      });
    }
  }

  onCvvInputChange(e) {
    this.setState({
      errors: [],
    });
    this.props.onCvvInputValueChange(e.target.value);
  }

  onCvvInputBlur() {
    const { cvvInputValue } = this.props;
    const [, errors] = cvcValidator(cvvInputValue, { isAmex: this.isAmex });
    this.setState({
      touched: cvvInputValue !== null && cvvInputValue !== '',
      errors,
    });
  }

  render() {
    const { isPaymentInProgress, cvvInputValue } = this.props;

    const { errors, touched } = this.state;

    debug(`render => isPaymentInProgress=${isPaymentInProgress}`);

    const maybeShowError = () => {
      if ((Array.isArray(errors) && errors.length === 0) || !touched) {
        return null;
      }

      let error = errors[0];

      if (errors.indexOf(ValidationErrorEnum.INVALID_CVC_AMEX) > -1) {
        error = ValidationErrorEnum.INVALID_CVC_AMEX;
      } else if (errors.indexOf(ValidationErrorEnum.INVALID_CVC_NON_AMEX) > -1) {
        error = ValidationErrorEnum.INVALID_CVC_NON_AMEX;
      }

      return (
        <div className="field-error-message-wrapper">
          <div className="error-icon-wrapper">
            <ErrorAlert />
          </div>
          <span className="error-message">
            <FormattedMessage id={this.errorTranslators(error)} defaultMessage="Invalid Field" />
          </span>

          <style jsx>{`
            .field-error-message-wrapper {
              margin-left: 15px;
              line-height: 14px;
              margin-top: 4px;

              .error-icon-wrapper {
                position: absolute;
              }

              .error-message {
                display: block;
                padding-left: 12px;
                font-style: italic;
                max-width: 150px;
                white-space: initial;
              }
            }
          `}</style>
        </div>
      );
    };

    return (
      <Fragment>
        <div className="cvv-wrapper" ref={this.ref}>
          <div className="labeled-input-wrapper">
            <label className="cvv-label">
              <FormattedMessage id="PAYFLOW_ENTER_CVV" defaultMessage="CVV Code" />
            </label>
            <Input
              disabled={isPaymentInProgress}
              isInError={touched && Array.isArray(errors) && errors.length > 0}
              onFocus={this.onCvvInputFocus}
              onChange={this.onCvvInputChange}
              onBlur={this.onCvvInputBlur}
              value={cvvInputValue}
              placeholder="123"
              inputMode="decimal"
              type="text"
              pattern="[0-9]*"
              maxLength={this.isAmex ? 4 : 3}
            />
          </div>
          {maybeShowError()}
        </div>

        <style jsx>{`
          .cvv-wrapper {
            margin: 0;

            .labeled-input-wrapper {
              display: flex;

              :global(div) {
                width: 57px;
              }
            }

            .cvv-label {
              white-space: nowrap;
              font-size: ${fontSize.xxs};
              color: ${colors.gray};
              margin: auto 15px;
              display: block;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

CvvInputControl.propTypes = {
  createPayment: PropTypes.func.isRequired,
  isPaymentInProgress: PropTypes.bool,
  bindFormSubmission: PropTypes.func.isRequired,
  onCvvInputValueChange: PropTypes.func.isRequired,
  cardType: PropTypes.string.isRequired,
  cvvInputValue: PropTypes.string.isRequired,
};
