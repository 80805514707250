import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';

import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import LabeledCheckbox from 'components/Shared/Inputs/LabeledCheckbox';
import SegmentIO from 'reporting/SegmentIO';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { PAYMENT_REJECT_TYPES } from 'store/payment/helpers';
import { setIsSavePaymentMethodChecked } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { colors, fontSize } from 'styles/cp';

export interface Props {
  startDate: string;
  companyName: string;
  amount: number;
  currency: string;
  isSavePaymentMethodChecked: boolean;
  paymentRejectType: string;
  setIsSavePaymentMethodChecked: (isSavePaymentMethodChecked: boolean) => void;
}

const SubscriptionConsent: React.FC<Props> = ({
  companyName,
  amount,
  startDate,
  currency,
  isSavePaymentMethodChecked,
  paymentRejectType,
  setIsSavePaymentMethodChecked,
}) => {
  const onChange = () => {
    const newValue = !isSavePaymentMethodChecked;
    SegmentIO.transactionEngaged({
      ui_object: 'checkbox',
      ui_object_detail: 'terms',
      ui_action: newValue ? 'enabled' : 'disabled',
      ui_access_point: 'transaction_flow',
    });
    setIsSavePaymentMethodChecked(newValue);
  };

  const style = { fontSize: fontSize.xxs, color: colors.darkGray };
  const formattedAmountElement = (
    <FormattedNumber value={amount} style="currency" currency={currency} />
  );

  return (
    <div className="subscription-consent-cta">
      <LabeledCheckbox
        id="cta"
        checked={isSavePaymentMethodChecked}
        onChange={onChange}
        error={paymentRejectType === PAYMENT_REJECT_TYPES.MISSING_CONSENT}
        label={
          <span style={style} id="cta-description">
            <FormattedMessage
              id="SUBSCRIPTION_CONSENT"
              defaultMessage={`I understand that I am purchasing a subscription and authorize ${companyName} to charge the payment method listed above on ${(
                <FormattedDateNoTimeZone value={startDate} />
              )} and monthly thereafter at ${amount}, plus tax, unless I cancel. I can cancel anytime by clicking on the manage my subscription link in the subscription emails.`}
              values={{
                companyName,
                amount: formattedAmountElement,
                startDate: <FormattedDateNoTimeZone value={startDate} />,
              }}
            />
          </span>
        }
      />
      <style jsx>{`
        .subscription-consent-cta {
          padding: 16px 0;
        }
      `}</style>
    </div>
  );
};

function mapStateToProps(store: any) {
  const { payment, sale, companyInfo } = store;
  const { startDate } = saleSelectors.subscriptionInfo(sale) || {};

  return {
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    startDate,
    isSavePaymentMethodChecked: payment.isSavePaymentMethodChecked,
    paymentRejectType: payment.paymentRejectType,
  };
}

export default connect(mapStateToProps, {
  setIsSavePaymentMethodChecked,
  // @ts-ignore
})(SubscriptionConsent);

export { SubscriptionConsent };
