import debugCreator from 'debug';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import ACHForm from 'components/Core/Payment/PaymentForm/Bank/Ach/ACHForm';
import EFTForm from 'components/Core/Payment/PaymentForm/Bank/Eft/EFTForm';
import ScrollingForm, {
  InjectedScrollingFormProps,
} from 'components/Core/Payment/PaymentForm/ScrollingForm/ScrollingForm';
import { IBankAccountCreateData } from 'components/Core/WalletForms/network/src/types';
import {
  BankTokenForm,
  FieldName,
  CreateBankTokenFormikBag,
} from 'components/Core/WalletForms/src/CreateBankTokenForm';
import { EnhancedFormikProps } from 'components/Core/WalletForms/src/Form';
import SegmentIO from 'reporting/SegmentIO';
import deviceInfo from 'server/helpers/deviceInfo';
import { walletTokenizationPaymentCreation } from 'store/payment/slice';
import { formErrorTranslator } from 'store/wallet/errorTranslators';
import { REMOTE_BUTTON_ACCESS_FUNCTIONALITY } from 'store/wallet/slice';
import { HideModalFunc, ShowModalFunc } from 'types/Modal';
import { TXN_MAP } from 'types/constants';

const debug = debugCreator('CPV2:BankForm');

type BankFormProps = {
  paymentStatus?: string;
  boundCreateBankFn?: () => void;
  bindFormSubmission?: (payload: { fn: () => void; functionality: string }) => any;
  bankDraft?: EnhancedFormikProps<IBankAccountCreateData>;
  updateFormDraft?: (draft: Partial<EnhancedFormikProps<unknown>>) => void;
  createPayment: typeof walletTokenizationPaymentCreation;
  stopPayment: () => void;
  paymentMethodType: string;
  showModal: ShowModalFunc;
  hideModal: HideModalFunc;
} & InjectedScrollingFormProps &
  WrappedComponentProps;

type BankFormState = {
  forceWidgetToReinitialize: boolean;
};

export class BankForm extends React.Component<BankFormProps, BankFormState> {
  isMobile: boolean;
  errorTranslator: Function;
  constructor(props: BankFormProps) {
    super(props);
    debug('constructor');
    this.isMobile =
      typeof window === 'object' &&
      typeof navigator === 'object' &&
      deviceInfo.isMobile(window.navigator.userAgent);
    this.state = {
      forceWidgetToReinitialize: true,
    };
    this.errorTranslator = formErrorTranslator();
  }

  handleFormSubmit = (
    values: IBankAccountCreateData,
    walletFormikBag: CreateBankTokenFormikBag
  ) => {
    const { setSubmitting } = walletFormikBag;
    this.props.createPayment({
      bankDetails: {
        ...values,
      },
    });
    setSubmitting(true);
  };

  bindSubmitForm = (fn: () => void) => {
    // Bind the Wallet Form submission trigger to the interested remote parties
    // such as Redux, or a parent component. If they trigger this fn function,
    // Wallet Form (Formik) will start the submission process.
    if (this.props.bindFormSubmission) {
      // Have we already done this before?
      if (this.props.boundCreateBankFn === fn) {
        debug('We have already bound the form submission trigger. No action needed.');
        return;
      }
    }
    this.props.bindFormSubmission &&
      setTimeout(
        () =>
          this.props.bindFormSubmission?.({
            fn,
            functionality: REMOTE_BUTTON_ACCESS_FUNCTIONALITY.BANK_CREATE_PAYMENT,
          }),
        0
      );
  };

  componentDidMount() {
    if (this.state.forceWidgetToReinitialize) {
      this.setState({
        forceWidgetToReinitialize: false,
      });
    }
  }

  render() {
    debug('render');
    const { paymentStatus, bankDraft, showModal, hideModal, paymentMethodType } = this.props;
    const {
      values: bankDraftValues,
      enumErrors: bankDraftEnumErrors,
      touched: bankDraftTouched,
    } = bankDraft || {};
    return (
      <BankTokenForm
        handleSubmit={this.handleFormSubmit}
        initialValues={bankDraftValues}
        bindSubmitForm={this.bindSubmitForm}
        validateOnBlur={true}
        validateOnChange={false}
        enableReinitialize={this.state.forceWidgetToReinitialize}
        paymentMethodType={paymentMethodType}
      >
        {({
          validateForm,
          isValidating,
          isSubmitting,
          setSubmitting,
          setErrors,
          setFieldError,
          setTouched,
          setFieldTouched,
          enumErrors,
          touched,
          values,
          dirty,
          setFieldValue,
        }) => {
          if (
            (bankDraftValues || dirty) &&
            bankDraftTouched &&
            this.state.forceWidgetToReinitialize
          ) {
            //@ts-ignore
            setErrors(bankDraftEnumErrors);
            validateForm().then(() => {
              setTouched(bankDraftTouched);
            });
          }
          // Tell Redux to stop payment. BankTokenForm found errors, the form is invalid.
          if (isSubmitting && !isValidating && Object.keys(enumErrors).length > 0) {
            setSubmitting(false);
            this.props.stopPayment();
          }
          // If Redux is telling us that there is an issue and the payment has failed, set the form to edit mode.
          if (isSubmitting && paymentStatus !== TXN_MAP.STATUS.IN_PROGRESS) {
            setSubmitting(false);
            if (
              !isValidating &&
              (paymentStatus === TXN_MAP.STATUS.ERROR || paymentStatus === TXN_MAP.STATUS.DECLINED)
            ) {
              if (paymentMethodType === 'bank') {
                setFieldValue('accountNumber', '');
                setFieldValue('accountNumberConfirm', '');
                setFieldValue('bankCode', '');
              } else if (paymentMethodType === 'eft') {
                setFieldValue('accountNumber', '');
                setFieldValue('institutionNumber', '');
                setFieldValue('transitNumber', '');
                setFieldValue('zipCode', '');
              }
            }
          }

          const onBankFieldFocus = (
            _field: { name: string; onFocus?: Function },
            fieldRef: React.RefObject<HTMLElement>
          ) => {
            return (e: React.FocusEvent<HTMLInputElement>) => {
              SegmentIO.bankFormFieldClicked(_field.name);
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              _field.onFocus && _field.onFocus(e);
              this.props.scrollToRef && fieldRef && this.props.scrollToRef(fieldRef, true);
            };
          };
          const onBankFieldChange = (_field: {
            name: string;
            onChange: Function;
            value: string;
          }) => {
            return (e: React.ChangeEvent<HTMLInputElement>) => {
              if (_field.name === 'accountType') {
                SegmentIO.transactionEngaged({
                  ui_object: 'dropdown',
                  ui_object_detail: _field.value.toLowerCase(),
                });
              }
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              // Respect the rest of the event propagation
              _field.onChange && _field.onChange(e);
              // Only clear the errors from the field if the value has changed.
              if (values[_field.name as FieldName] !== e.target.value) {
                // @ts-ignore
                setFieldError(_field.name as FieldName, null);
              }
              if (_field.name === 'accountType') {
                this.props.updateFormDraft &&
                  this.props.updateFormDraft({
                    values: {
                      ...values,
                      accountType: e.target.value,
                    },
                    enumErrors,
                    touched,
                  });
                setTimeout(() => validateForm(), 0);
              }
            };
          };
          const onFieldBlur = (_field: { name: string; onBlur: Function }) => {
            return (e: React.FocusEvent<HTMLInputElement>) => {
              const value = e.target.value;
              // For tests this is necessary
              if (!e.target.name) {
                e.target.name = _field.name;
              }
              _field.onBlur && _field.onBlur(e);
              // If the form is dirty, then update the form draft on the Redux Store.
              this.props.updateFormDraft &&
                this.props.updateFormDraft({
                  values,
                  enumErrors,
                  touched,
                });
              // If the value is empty then consider this field untouched.
              setFieldTouched(_field.name, value !== null && value !== '');
            };
          };
          const AchOrEft = () => {
            const formProps = {
              onBankFieldFocus,
              onFieldBlur,
              onBankFieldChange,
              isSubmitting,
              bankDraftEnumErrors: enumErrors,
              bankDraftTouched: touched,
            };
            const { paymentMethodType } = this.props;
            if (paymentMethodType === 'bank') {
              return <ACHForm {...formProps} />;
            } else if (paymentMethodType === 'eft') {
              return <EFTForm hideModal={hideModal} showModal={showModal} {...formProps} />;
            }
            return null;
          };
          return <div>{AchOrEft()}</div>;
        }}
      </BankTokenForm>
    );
  }
}

export const BankFormWithIntl = injectIntl(BankForm);
export default ScrollingForm(BankFormWithIntl);
